import { store } from '@/store/store';
import { PracticaDeporte } from '@/shared/dtos/PracticaDeporte';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'PracticaDeporteModule',
    store,
    dynamic: true
})
class PracticaDeporteModule extends VuexModule {
    public PracticaDeportes: PracticaDeporte[] = [];
    public PracticaDeporte: PracticaDeporte = new PracticaDeporte();

    @Action({ commit: 'onGetPracticaDeportes' })
    public async getPracticaDeportes() {
        return await ssmHttpService.get(API.PracticaDeporte);
    }

    @Action({ commit: 'onGetPracticaDeporte' })
    public async getPracticaDeporte(id: any) {
        return await ssmHttpService.get(API.PracticaDeporte + '/' + id);
    }

    @Mutation
    public onGetPracticaDeportes(res: PracticaDeporte[]) {
        this.PracticaDeportes = res ? res.map((x) => new PracticaDeporte(x)) : [];
    }

    @Mutation
    public onGetPracticaDeporte(res: PracticaDeporte) {
        this.PracticaDeporte = new PracticaDeporte(res);
    }

}
export default getModule(PracticaDeporteModule);