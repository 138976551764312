


























import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import { PracticaDeporte } from "@/shared/dtos/PracticaDeporte";
import PracticaDeporteModule from "@/store/modules/PracticaDeporte-module";
import { UtilsString } from "@/utils/utils-string";
@Component({
  components: { CompHeader: () => import("./CompHeaderLabel.vue") }
})
export default class CompPracticaDeporte extends Vue {
  @PropSync("deporte", { type: String }) synced!: string;
  @Prop() index!: number;
  public Seleccionado: any = {
    text: "",
    value: -1
  };
  public async created() {
    await PracticaDeporteModule.getPracticaDeportes();
  }
  public get items() {
    let valores: any[] = [];
    for (let i = 0; i < PracticaDeporteModule.PracticaDeportes.length; i++) {
      const element = PracticaDeporteModule.PracticaDeportes[i];
      valores.push({
        text: element.nombre,
        value: element.id
      });
      if (this.synced === element.nombre) {
        this.Seleccionado = valores[valores.length - 1];
        this.$emit("validado", { index: this.index, valido: true });
      }
    }
    return valores;
  }
  @Watch("Seleccionado")
  public ChangeHorario() {
    for (let i = 0; i < PracticaDeporteModule.PracticaDeportes.length; i++) {
      const element = PracticaDeporteModule.PracticaDeportes[i];
      if (element.id === this.Seleccionado) {
        this.synced = element.nombre;
        this.$emit("validado", { index: this.index, valido: true });
      }
    }
  }
  public get ItemSelect(): PracticaDeporte {
    for (let i = 0; i < PracticaDeporteModule.PracticaDeportes.length; i++) {
      const element = PracticaDeporteModule.PracticaDeportes[i];
      if (this.synced === element.nombre) {
        this.$emit("validado", { index: this.index, valido: true });
        return element;
      }
    }

    return new PracticaDeporte();
  }
}
